import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import settings from "./backend/LocalSettings";
import VueResource from "vue-resource";
import locale from "element-ui/lib/locale/lang/en"
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// javascript import for when you're importing the css directly in your javascript
import "vue-navigation-bar/dist/vue-navigation-bar.css";
import VueNavigationBar from "vue-navigation-bar";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import SelectClienti from '@/widgets/SelectClientiWidget'; 




// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

var currencyFormatter = require('currency-formatter');

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false;

// Vue.use(BootstrapVue);
// Vue.use(IconsPlugin)
Vue.component("vue-navigation-bar", VueNavigationBar);

Vue.component('v-select', vSelect)
Vue.component('select-clienti', SelectClienti)


Vue.use(VueResource);
Vue.use(ElementUI, { locale });

Vue.http.options.xhr = { withCredentials: true };
Vue.http.options.emulateJSON = true;
Vue.http.headers.common["Token"] = settings.get_token();

Vue.http.options.root = settings.BASE_URL;

Vue.filter( 'momentformat', function(value){
    return value;
} );

Vue.filter( 'moneyformat', function(value){
    return currencyFormatter.format(value, {
        symbol: '',
        decimal: ',',
        thousand: '.',
        precision: 2,
        format: '%v'
      });
} );

Vue.filter( 'date_nice_format', function(value){
  return moment(value, "YYYY-MM-DD").format("DD-MM-YYYY");
} );

Vue.filter( 'date_time_nice_format', function(value){
    return moment(value, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY HH:mm");
} );
Vue.filter( 'date_time_to_date_only_format', function(value){
    return moment(value, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY");
} );


Vue.prototype.$has_right = function (rightKey)
{
    var ret             = false;
    var drepturiDecoded = settings.get_drepturi();
    var numarUnic       = (drepturiDecoded.length - 1) * (458 + 73 - 23)
    var caractereUnice  = 'nimic' + numarUnic
    const verificareCrc = CryptoJS.MD5(CryptoJS.enc.Utf8.parse(caractereUnice)).toString();
    if (drepturiDecoded.indexOf(verificareCrc) !== -1)
    {
        var indexDrept = drepturiDecoded.indexOf(rightKey)
        if (indexDrept !== -1) ret = true;
    }
    return ret;
}


var app = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');

