<template>
        <el-dialog :title="mode=='add' ? 'Adaugare statie noua' : 'Editare statie' " :visible.sync="showPopup" class="my-dialog-class"  :close-on-click-modal="false">
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                <el-col :md='8'>
                    <el-form-item label='Locatie' >
                        <el-select filterable v-model='selectedObject.IdLocatie'  class='full-width'>   
                            <el-option v-for='item in Info.locatii' :key="'locatii' + item.Id" :label='item.Nume' :value='item.Id'>
                                <el-tooltip effect="dark" :content="item.Nume" placement="top-start">
                                    <span style="float: left">{{ item.Nume }}</span>
                                </el-tooltip>
                                <span v-if="item.Sters == '1'" style="float: right; color: red; font-size: 13px"> 
                                    <el-tag type="danger" size="mini">STERS</el-tag>
                                </span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Nume' >
                        <el-input  class='full-width' v-model='selectedObject.Nume' />
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Cod' >
                        <el-input  class='full-width' v-model='selectedObject.Cod' />
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='TipStatie' >
                        <el-select class='full-width' v-model='selectedObject.TipStatie' >
                            <el-option label='Desktop' value='desktop'></el-option>
                            <el-option label='Mobile' value='mobile'></el-option>
                            <el-option label='Kitchen/Customer Display' value='kitchen-display'></el-option>
                            
                        </el-select>
                    </el-form-item>
                </el-col>            
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Statii_dialog",
        extends: BasePage,
        data () {
            return {
                baseUrl :'',
                showPopup: false,
                mode: 'add',
                selectedObject: { IdLocatie: '' , Nume: '' , Cod: '' , TipStatie: 'desktop' },
                Info:{
                    locatii: [],                 },
                rules: {
                    //   Nume: [ { required: true, message: "Campul este obligatoriu" } ]
                    IdLocatie: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Nume: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Cod: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    TipStatie: [ { required: true, message: 'Campul este obligatoriu' } ], 
                }
            }
        },
        methods: {
            show_me: async function( id, idClient, idLocatie ) {
                this.showPopup        = true;
                var response          = await this.post("statii/get_info_for_dialog", {idClient: idClient} );
                this.Info.locatii     = response.locatii;      
                if( id == null )
                {
                    this.mode = "add";
                    
                    if( idLocatie == '-1') idLocatie = this.Info.locatii[0].Id;
                    
                    this.selectedObject.IdLocatie = idLocatie;
                }
                else
                {
                    this.mode             = "edit";
                    var            result = await this.post("statii/get_info_item_dialog", { id: id } );
                    this.selectedObject   = result.Item;
                }
            },

            async get_info() {
                var response = await this.post("statii/get_info_for_dialog", {idClient: -1} );
                this.Info.locatii = response.locatii;            
            },

            save: async function() {
                //verificam daca codul incepe cu B - desktop, kd - kitchen display , altfel e mobil
                var cod = this.selectedObject.Cod + "";
                var tip = this.selectedObject.TipStatie;

                if( cod.length > 0 ) {
                    if( tip == "desktop" && !cod.startsWith("B") ) {
                        var response = await this.$confirm('Codul nu incepe cu B. Continuam?', 'Warning', { confirmButtonText: 'OK, continua', cancelButtonText: 'Renunta',type: 'warning'});
                        if( response != "confirm" ){
                            return;
                        }
                    }

                    if( tip == "kitchen-display" && !cod.startsWith("kd") ) {
                        var response = await this.$confirm('Codul nu incepe cu kd. Continuam?', 'Warning', { confirmButtonText: 'OK, continua', cancelButtonText: 'Renunta',type: 'warning'});
                        if( response != "confirm" ){
                            return;
                        }
                    }
                    
                    if( tip == "mobile" && ( cod.startsWith("B") || cod.startsWith("kd") ) ) {
                        var response = await this.$confirm('Codul nu pare de mobile. Continuam?', 'Warning', { confirmButtonText: 'OK, continua', cancelButtonText: 'Renunta',type: 'warning'});
                        if( response != "confirm" ){
                            return;
                        }
                    }
                }
                
                if( this.selectedObject.IdLocatie == ""){
                    this.$message.error("Locatia este obligatorie!");
                    return;
                }
                

                var confirm =  await this.$confirm(
                    this.mode == "add" ? 
                        `Sunteti sigur ca doriti sa adaugam o statie noua pe nume '${this.selectedObject.Nume}'?` :
                        `Sunteti sigur ca doriti sa salvam modificarile la statia '${this.selectedObject.Nume}'?` 
                , 'Warning');

                //daca sunt in this.mode=='add' nu il lasam sa adauge o statie noua pe o locatie stearsa
                if( this.mode == "add" && this.Info.locatii.find( x => x.Id == this.selectedObject.IdLocatie && x.Sters == '1' ) )
                {
                    this.$message.error("Nu puteti adauga o statie noua pe o locatie stearsa!");
                    return;
                }


                if( confirm )
                {
                    this.$refs['my-form'].validate( async(valid) => {
                        if (valid)
                        {
                            var response = await this.post("statii/save", { mode: this.mode, object: this.selectedObject } );
                            if( !response.Eroare ){
                                this.showPopup = false;
                                this.$emit("save");
                            }else{
                                this.$message.error(response.MesajEroare);
                            }
                            
                        }
                    });
                }
            }
        },
        mounted: function() {
            this.baseUrl = settings.BASE_URL;
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>