<template>
        <el-dialog :title="mode=='add' ? 'Adaugare locatie noua' : 'Editare locatie' " :visible.sync="showPopup" class="my-dialog-class"  :close-on-click-modal="false">
            
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                
            <el-col :md='8'>
                <el-form-item label='Client' >
                     <select-clienti v-model="selectedObject.IdClient" clearable :toti="false" size="small" class='full-width' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Nume' >
                    <el-input  size="small"  class='full-width' v-model='selectedObject.Nume' clearable />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='PrelungireAutomata' >
                    <el-checkbox size="small" :disabled="!$has_right('locatii_prelungire_automata')"  v-model='selectedObject.PrelungireAutomata' true-label='1' false-label='0'> PrelungireAutomata </el-checkbox>
                </el-form-item>
            </el-col>
            </el-row>
            
            <el-row :gutter="15" >
            <el-col :md='4'>
                <el-form-item label='Cui' >
                    <el-input  size="small"  class='full-width' v-model='selectedObject.Cui' clearable/>
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Societate' >
                    <el-input  size="small"  class='full-width' v-model='selectedObject.Societate' clearable/>
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Adresa' >
                    <el-input   size="small" class='full-width' v-model='selectedObject.Adresa' clearable/>
                </el-form-item>
            </el-col>
            <el-col :md='4'>
                <el-form-item label='Judet' >
                    <el-select  size="small" v-model='selectedObject.IdJudet'  class='full-width' filterable>
                         <el-option v-for='item in Info.judete' :key="'judet-' + item.Id" :label='item.Nume' :value='item.Id'></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :md='3'>
                <el-form-item label='NrContract' >
                    <el-input  size="small"  class='full-width' v-model='selectedObject.NrContract' clearable/>
                </el-form-item>
            </el-col>
            <el-col :md='5'>
                <el-form-item label='Data Contract' >
                    <el-date-picker  size="small" class='full-width' v-model='selectedObject.DataContract' type='date' value-format='yyyy-MM-dd' format='dd.MM.yyyy' />
                </el-form-item>
            </el-col>
        </el-row>

        <el-row :gutter="5">
            
         

            <el-col :md='6'>
                <el-form-item label='Data Start Mentenanta' >
                    <el-date-picker   :disabled="!$has_right('data_start_mentenanta')"  size="small" class='full-width' v-model='selectedObject.DataStartMentenanta' type='date' value-format='yyyy-MM-dd' format='dd.MM.yyyy' />
                </el-form-item>
            </el-col>

            
            
            <el-col :md='6'>
                <el-form-item label='Zile Prelungire Automata' >
                    <el-input-number :disabled="!$has_right('zile_prelungire_automata')" size="small" class='full-width' v-model='selectedObject.NrZilePrelungireAutomata' :precision='0' :max='999999'></el-input-number>
                </el-form-item>
            </el-col>

            
            
            
            <el-col :md='6'>
                <el-form-item label='Zile Max Licentiere Manuala' >
                    <el-input-number :disabled="!$has_right('zile_maxim_licentiere_manuala')" size="small" class='full-width' v-model='selectedObject.NrZileMaximLicentiereManuala' :precision='0' :max='999999'></el-input-number>
                </el-form-item>
            </el-col>

            <el-col :md='6'>
                <el-form-item label='Nr Maxim Luni Neplatite' >
                    <el-input-number
                        :disabled="!$has_right('nr_maxim_luni_neplatite')" 
                      size="small" class='full-width' v-model='selectedObject.NrMaximLuniNeplatite' :precision='0' :max='999999'></el-input-number>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="15">

            <el-col :md='8'>
                <el-form-item label='Persoana Contact' >
                    <el-input  size="small"  class='full-width' v-model='selectedObject.PersoanaContact' clearable/>
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Telefon' >
                    <el-input  size="small"  class='full-width' v-model='selectedObject.Telefon' clearable />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Email' >
                    <el-input  size="small"  class='full-width' v-model='selectedObject.Email' clearable/>
                </el-form-item>
            </el-col>

            <el-col :md='8'>
                <el-form-item label='Firma' >
                    <el-select size="small" v-model='selectedObject.IdFirma'  class='full-width' filterable>
                         <el-option v-for='item in Info.firme' :key="'firma-' + item.Id" :label='item.Nume' :value='item.Id'></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :md='4'>
                <el-form-item label='Valuta' >
                    <el-select size="small"  v-model='selectedObject.IdValuta'  class='full-width' filterable>
                         <el-option v-for='item in Info.valute' :key="'valuta-' + item.Id" :label='item.Nume' :value='item.Id'></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :md='6'>
                <el-form-item label='Valoare Mentenanta Lunara' >
                    <el-input   size="small" class='full-width' v-model='selectedObject.ValoareMentenantaLunara' clearable/>
                </el-form-item>
            </el-col>

            <el-col :md='4' >
                <el-form-item label='Operat in facturier' >
                    <el-checkbox :disabled="!$has_right('locatii_status_operare')" size="small"  v-model='selectedObject.OperatInFacturier' true-label='1' false-label='0'> Operat </el-checkbox>
                </el-form-item>
            </el-col>

            </el-row>
        <el-row :gutter="15">
            <el-col :md='6'>
                <el-form-item label='Client Branding' >
                    <el-input   size="small" class='full-width' v-model='selectedObject.ClientBranding' />
                </el-form-item>
            </el-col>
            <el-col :md='6'>
                <el-form-item label='Tip facturare' >
                    <el-select v-model="selectedObject.TipFacturare" size="small" class='full-width'>
                        <el-option value="altele" label="Altele"/>
                        <el-option value="factura" label="Factura"/>
                        <el-option value="proforma" label="Proforma"/>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="15">

            <el-col :md='24'>
                <el-form-item label='Observatii' >
                    <el-input   size="small" class='full-width' v-model='selectedObject.Observatii' type='textarea' />
                </el-form-item>
            </el-col>            
            
            <el-col :md='6'>
                <el-form-item label='Token E-Factura' >
                    <el-input   size="small" class='full-width' v-model='selectedObject.Efactura_Token' :readonly="true" />
                </el-form-item>
            </el-col>

            <el-col :md='6'>
                <el-form-item label='Data expirarii E-Factura' >
                    <el-input   size="small" class='full-width' v-model='selectedObject.Efactura_DataExpirare' :readonly="true" />
                </el-form-item>
            </el-col>

            <el-col :md='6'>
                <el-form-item label='Mod DEV E-Factura' >
                    <el-checkbox v-model="selectedObject.Efactura_Dev" true-label="1" false-label="0">Mod DEV activ</el-checkbox>
                </el-form-item>
            </el-col>

            <el-col :md='6'>
                <el-form-item label='Arhivare facturi emise e-factura' >
                    <el-checkbox size="small"  v-model='selectedObject.Efactura_ArhivareActiva' true-label='1' false-label='0'> Arhivare activa </el-checkbox>
                </el-form-item>
            </el-col>

            </el-row>
            <hr/>
            <el-row>

            <el-col :md='4' style="background-color: lightblue;">
                <el-form-item label='Copiaza token la:' >
                    <select-clienti v-model="copyTo.IdClient" clearable :toti="false" size="small" class='full-width' @change="get_locatii_client"/>
                </el-form-item>
            </el-col>

            <el-col :md='6' style="background-color: lightblue;">
                <el-form-item label='Copiaza token in locatia:' >
                     <el-select size="small" popper-class='my-popper' clearable v-model='copyTo.IdLocatie'  class='full-width' filterable>      
                         <el-option v-for='item in locatii' :key="'locatie-' + item.Id" :label='item.Nume' :value='item.Id'></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :md='2' style="background-color: lightblue;">
                <el-form-item label='_' >
                     <el-button size="small" @click="copiaza_token">Copiaza</el-button>
                </el-form-item>
            </el-col>

            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';
    import moment from 'moment';
    

    export default {
        name: "Locatii_dialog",
        extends: BasePage,
        components: { 
            
        },
        data () {
            return {
                baseUrl :'',
                showPopup: false,
                mode: 'add',
                copyTo:{
                    IdClient: '',
                    IdLocatie: ''
                },
                selectedObject: { 
                    IdClient: '' , Nume: '',  Cui: '' , Societate: '' , Adresa: '', 
                    IdJudet: -1, NrContract: '' , DataContract: '' , PrelungireAutomata: '' , 
                    NrZilePrelungireAutomata: '15', 
                    ClientBranding: '',
                    NrZileMaximLicentiereManuala: '180', 
                    NrMaximLuniNeplatite: '3',
                    DataStartMentenanta:'',
                    IdValuta: '1', IdFirma: '1', ValoareMentenantaLunara: 0,
                    OperatInFacturier: '0',
                    PersoanaContact: '' , Telefon: '' , Email: '' , Observatii: '',
                    Efactura_ArhivareActiva: '1',
                    TipFacturare:'proforma',
                    Efactura_Token: '', Efactura_DataExpirare: '', Efactura_Dev:'0'
                },
                locatii: [],
                Info: { 
                    judete:  [],
                },
                rules: {
                    //   Nume: [ { required: true, message: "Campul este obligatoriu" } ]
                    IdClient: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Nume: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Cui: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Societate: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Adresa: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    NrContract: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    DataContract: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    PrelungireAutomata: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    NrZilePrelungireAutomata: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    NrZileMaximLicentiereManuala: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    NrMaximLuniNeplatite: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    PersoanaContact: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Telefon: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Email: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Observatii: [ { required: false, message: 'Campul este obligatoriu' } ], 
                }
            }
        },
        methods: {
            show_me: async function( id ) {
                this.showPopup        = true;
                if( id == null )
                {
                    this.mode = "add";
                    this.selectedObject.Nume        = '';
                    this.selectedObject.Adresa      = '';
                    this.selectedObject.Observatii  = '';
                    this.selectedObject.OperatInFacturier               = '0';
                    this.selectedObject.NrZileMaximLicentiereManuala    = '33';
                    this.selectedObject.NrMaximLuniNeplatite            = '2';
                    this.selectedObject.PrelungireAutomata              = '1';
                    this.selectedObject.Efactura_ArhivareActiva         = '1';
                    

                    var id_client_selectat      = settings.get_id_client_selectat();
                    if( id_client_selectat != null && id_client_selectat != '' ){
                        this.selectedObject.IdClient = id_client_selectat;
                    }

                    this.selectedObject.DataContract         = moment().format("YYYY-MM-DD");
                    this.selectedObject.DataStartMentenanta  = this.selectedObject.DataContract;
                }
                else
                {
                    this.mode             = "edit";
                    var            result = await this.post("locatii/get_info_item_dialog", { id: id } );
                    this.selectedObject   = result.Item;
                    
                }
            },

            async get_locatii_client()
            {
                var response = await this.post("locatii/get_locatii_client", { IdClient: this.copyTo.IdClient } );
                this.locatii = response.locatii;
                if(this.locatii.length > 0){
                    this.copyTo.IdLocatie = this.locatii[0].Id;
                }else{
                    this.copyTo.IdLocatie = '-1';
                }
            },

            async copiaza_token()
            {
                var response = await this.post("locatii/copiaza_token", { 
                    IdLocatieSrc:   this.selectedObject.Id,
                    IdClient:       this.copyTo.IdClient, 
                    IdLocatie:      this.copyTo.IdLocatie 
                } );
                this.$notify.success({ title: 'Am copiat', message: "Am copiat token!" });
            },
            
            async get_info(){
                var response = await this.post("locatii/get_info_for_dialog" );
                
                this.Info.judete             = response.judete;
                this.Info.firme              = response.firme;
                this.Info.valute             = response.valute;     
                
                this.selectedObject.IdJudet  = this.Info.judete [0].Id;
            },

            save: async function() {
                var confirm =  await this.$confirm(
                    this.mode == "add" ? 
                        `Sunteti sigur ca doriti sa adaugam o locatie noua pe nume '${this.selectedObject.Nume}'?` :
                        `Sunteti sigur ca doriti sa salvam modificarile la locatia '${this.selectedObject.Nume}'?` 
                , 'Warning');
                if( confirm )
                {
                    this.$refs['my-form'].validate( async(valid) => {
                        if (valid)
                        {
                            var response = await this.post("locatii/save", { mode: this.mode, object: this.selectedObject } );
                            if( response.MesajAtentionare != "" ) 
                            {
                                this.$notify.error({ title: 'Error', message: response.MesajAtentionare });
                            }
                            this.showPopup = false;
                            this.$emit("save");
                        }
                    });
                }
            }
        },
        mounted: function() {
            this.baseUrl = settings.BASE_URL;
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>