import { render, staticRenderFns } from "./SelectClientiWidget.vue?vue&type=template&id=46aca594&scoped=true&"
import script from "./SelectClientiWidget.vue?vue&type=script&lang=js&"
export * from "./SelectClientiWidget.vue?vue&type=script&lang=js&"
import style0 from "./SelectClientiWidget.vue?vue&type=style&index=0&id=46aca594&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46aca594",
  null
  
)

export default component.exports