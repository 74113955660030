<template>
    <base-page>
    
        <el-card  class="filtre-albastre no-padding-card" >
            <el-collapse>
                <el-collapse-item  name="1">
                    <template slot="title">
                        <el-button style="margin-right: 15px;" icon="el-icon-plus" size="mini" type='success'  @click='show_dialog("-1")'>
                            Task
                        </el-button>
                        FILTRE
                    </template>
                    <div class="filtre" >
                        <el-form @submit.prevent.native='refresh_info()'>
                            <el-row :gutter="20">       
                                <el-col :md='4'>
                                    <el-form-item label='Asignat lui' >
                                        <el-select class='full-width' v-model='Filters.IdAsignat' filterable :clearable="true"  @clear="select_toti_asignatii()" @change="refresh_info()">
                                            <el-option label='Toti' value='-1'/>
                                            <el-option v-for='item in Info.Utilizatori' :key="'user' + item.Id" :value='item.Id' :label="item.Nume" >
                                                <span style="float: left">{{ item.Nume }}</span>
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col :md='4'>
                                    <el-form-item label='Client' >
                                        <el-select id="TxtClient" class='full-width' @change="change_client()" v-model='Filters.IdClient' filterable :clearable="true" @clear="select_toti_clientii()" >
                                            <el-option label='Toti' value='-1'/>
                                            <el-option v-for='item in Info.Clienti' :key="'clienti' + item.Id" :value='item.Id' :label="item.Nume" >
                                                <span style="float: left">{{ item.Nume }}</span>
                                                <span v-if="item.Sters == '1'" style="float: right; color: red; font-size: 13px"> 
                                                    <el-tag type="danger" size="mini">STERS</el-tag>
                                                </span>
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col :md='3'>
                                    <el-form-item label='Status' >
                                        <el-select class='full-width' @change="refresh_info()" v-model='Filters.Status' >
                                            <el-option label='Toate taskurile' value='-1'/>
                                            <el-option label='In asteptare' value='normal' />
                                            <el-option label='Finalizate' value='finalizat'/>
                                            <el-option label='Anulate' value='renuntare'/>
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col :md='3'>
                                    <el-form-item label='Mesaj' >
                                        <el-input v-model='Filters.Mesaj'  placeholder="mesaj..." clearable/>
                                    </el-form-item>
                                </el-col>

                                <el-col :md='3' :xs='12' class='filter-buttons'>
                                    <el-form-item :label="PaginationInfo.RowCount + ' items'" >
                                        <el-button size="mini" type='primary' native-type='submit' @click='refresh_info()'> Cauta </el-button>
                                    </el-form-item>
                                </el-col>

                               

                                

                            </el-row>
                        </el-form>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </el-card>

        <el-table :data="Results" v-loading="loadingVisible" :row-class-name="tableRowClassName">
            <el-table-column type="expand" width="20">
                <template slot-scope="props">
                    <div>
                        <el-table :data="props.row.Copii" style="margin: 0 auto; max-width: 800px" :border="true">
                            <el-table-column prop="DataCreare" label="Data Creare"/>
                            <el-table-column prop="Mesaj" label="Mesaj" />
                            <el-table-column prop="DataOperare" label="Ultima modificare"/>
                        </el-table>
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="Info" v-if="is_mobile()" width="160">
                <template slot-scope="scope">
                    <div v-if="scope.row.NrAmanari > 0">
                        <el-tag type="danger" size="mini">{{scope.row.NrAmanari}} amânari</el-tag>
                        <hr/>
                    </div>
                    <div>
                        <strong style="color: black;">{{scope.row.Client}}</strong><br/>
                        {{scope.row.Locatie}}
                    </div>
                    <hr/>
                    <div  style="font-size:10px">
                        <el-tag  v-if="scope.row.Status=='normal'" size="mini">In asteptare</el-tag>
                        <el-tag  v-if="scope.row.Status=='amanat'" type="warning" size="mini">Amânat</el-tag>
                        <el-tag  v-if="scope.row.Status=='renuntare'" type="danger" size="mini">Anulat</el-tag>
                        <el-tag  v-if="scope.row.Status=='finalizat'" type="success" size="mini">Finalizat</el-tag>
                        <br/>
                        <el-tag v-if="scope.row.Status!='normal'" size="mini">
                            {{scope.row.DataOperare}}
                        </el-tag>
                        <hr/>
                        Creat de <el-tag size='mini'>{{scope.row.User}}</el-tag> <br/>
                        <el-tag size='mini'>{{scope.row.DataCreare}}</el-tag>  <br/>
                        <hr/>
                        Pentru <el-tag size='mini' type="success">{{scope.row.Asignat}}</el-tag> <br/>
                        <el-tag size='mini' type="success" style="color:black; font-weight:bold">{{scope.row.TermenFinalizare}}</el-tag> 
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="Mesaj" v-if="is_mobile()">
                <template slot-scope="scope">
                    <el-popover placement="bottom" width="150" trigger="click" v-if="scope.row.Status != 'amanat'">
                        <div>
                            <el-tooltip content="Reactivare" v-if="scope.row.Status == 'finalizat' || scope.row.Status == 'renuntare'">
                                <el-button type="danger" size='mini' icon="el-icon-back"  circle @click="reactivare(scope.row.Id)" />
                            </el-tooltip>

                            <el-tooltip content="Validare" v-if="scope.row.Status == 'normal'">
                                <el-button type="success" size='mini' icon="el-icon-check"  circle @click="validare(scope.row.Id)" />
                            </el-tooltip>

                            <el-tooltip content="Editare"  v-if="scope.row.Status != 'finalizat' && scope.row.Status != 'amanat' && scope.row.Status != 'renuntare'">
                                <el-button type="primary" size='mini' icon="el-icon-edit"  circle @click="show_dialog_edit(scope.row.Id, scope.row.IdLocatie)" />
                            </el-tooltip>

                            <el-tooltip content="Amânare" v-if="scope.row.Status != 'finalizat' && scope.row.Status != 'amanat' && scope.row.Status != 'renuntare'">
                                <el-button type="warning" size='mini' icon="el-icon-timer"  circle @click="show_dialog_amanare(scope.row.Id, scope.row.IdLocatie)" />
                            </el-tooltip>

                            <el-tooltip content="Anulare" v-if="scope.row.Status != 'finalizat' && scope.row.Status != 'amanat' && scope.row.Status != 'renuntare'">
                                <el-button type="danger" size='mini' icon="el-icon-delete"  circle @click="renuntare(scope.row.Id)" />
                            </el-tooltip>
                        </div>
                        <el-button slot="reference" style="float:right; padding: 10px !important; margin-left: 5px" icon="el-icon-arrow-down"  size='mini'/>
                    </el-popover>
                    <div>
                        #<strong>{{scope.row.Id}}</strong><hr/>
                        {{scope.row.Mesaj}}
                    </div>
                </template>
            </el-table-column>

            <el-table-column min-width="50" label="Id" v-if="!is_mobile()">
                <template slot-scope="scope">
                    #<strong>{{scope.row.Id}}</strong>
                </template>
            </el-table-column>

            <el-table-column min-width="170" label="Client" v-if="!is_mobile()">
                <template slot-scope="scope">
                    <strong>{{scope.row.Client}}</strong> <br/>
                    {{scope.row.Locatie}}
                </template>
            </el-table-column>
            <el-table-column prop="DataCreare" min-width="170" label="Data Creare" v-if="!is_mobile()">     
                <template slot-scope="scope">
                    {{scope.row.DataCreare}}
                    <br/>
                    de <el-tag size='mini'>{{scope.row.User}}</el-tag>
                </template>
            </el-table-column>

            <el-table-column  label="Termen finalizare" min-width="150" v-if="!is_mobile()">
                <template slot-scope="scope">
                    <el-tag size='mini' style="color:black; font-weight:bold">{{scope.row.TermenFinalizare}}</el-tag>
                </template>
            </el-table-column> 
            <el-table-column prop="Asignat" min-width="180" label="Asignat"  v-if="!is_mobile()">
                <template slot-scope="scope">
                    <el-tag type="success" style="font-weight:bold; color: black">{{scope.row.Asignat}}</el-tag>
                </template>
            </el-table-column>

            <el-table-column prop="Mesaj"   label="Mesaj" min-width="500"  v-if="!is_mobile()"/>

            <el-table-column prop="Status" label="Status" min-width="150" v-if="!is_mobile()">
                <template slot-scope="scope">
                    <div v-if="scope.row.NrAmanari > 0">
                        <el-tag type="danger" size="mini">{{scope.row.NrAmanari}} amânari</el-tag>
                    </div>
                    <el-tag  v-if="scope.row.Status=='normal'" size="mini">In asteptare</el-tag>
                    <el-tag  v-if="scope.row.Status=='amanat'" type="warning" size="mini">Amânat</el-tag>
                    <el-tag  v-if="scope.row.Status=='renuntare'" type="danger" size="mini">Anulat</el-tag>
                    <el-tag  v-if="scope.row.Status=='finalizat'" type="success" size="mini">Finalizat</el-tag>

                    <br/>
                    <el-tag v-if="scope.row.Status!='normal'" size="mini">
                        {{scope.row.DataOperare}}
                    </el-tag>

                </template>
            </el-table-column>


            <el-table-column label="Actiuni" width="170" v-if="!is_mobile()">
                <template slot-scope="scope">

                        <el-tooltip content="Reactivare" v-if="scope.row.Status == 'finalizat' || scope.row.Status == 'renuntare'">
                            <el-button type="danger" size='mini' icon="el-icon-back"  circle @click="reactivare(scope.row.Id)" />
                        </el-tooltip>

                        <el-tooltip content="Validare" v-if="scope.row.Status == 'normal'">
                            <el-button type="success" size='mini' icon="el-icon-check"  circle @click="validare(scope.row.Id)" />
                        </el-tooltip>

                        <el-tooltip content="Editare"  v-if="scope.row.Status != 'finalizat' && scope.row.Status != 'amanat' && scope.row.Status != 'renuntare'">
                            <el-button type="primary" size='mini' icon="el-icon-edit"  circle @click="show_dialog_edit(scope.row.Id, scope.row.IdLocatie)" />
                        </el-tooltip>

                        <el-tooltip content="Amânare" v-if="scope.row.Status != 'finalizat' && scope.row.Status != 'amanat' && scope.row.Status != 'renuntare'">
                            <el-button type="warning" size='mini' icon="el-icon-timer"  circle @click="show_dialog_amanare(scope.row.Id, scope.row.IdLocatie)" />
                        </el-tooltip>

                        <el-tooltip content="Anulare" v-if="scope.row.Status != 'finalizat' && scope.row.Status != 'amanat' && scope.row.Status != 'renuntare'">
                            <el-button type="danger" size='mini' icon="el-icon-delete"  circle @click="renuntare(scope.row.Id)" />
                        </el-tooltip>
                
                </template>
            </el-table-column>
        </el-table>
        
        <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
        
        <TaskAddEdit-dialog ref="dlg-add-edit-task" @save="refresh_info()"></TaskAddEdit-dialog>
    </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import eventBus from '@/backend/EventBus';
import TaskAddEdit_dialog from '@/pages/locatii/TaskAddEdit_dialog.vue';

export default {
name: "taskuri",
extends: BasePage,
components: {
    'base-page': BasePage,
    'TaskAddEdit-dialog': TaskAddEdit_dialog
},
data () {
    return {
        Results: [],
        base_url: '',
        Info: { Clienti: [], firme: [], Utilizatori: [] },
        Filters: { IdClient: "-1", Locatie: "", Mesaj: "", IdAsignat: "-1", Status: "normal"},
        OrderBy: { Client: '' },

        PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
    }
},
methods: {

    tableRowClassName({row, rowIndex}){
        return "rand-"+row.CuloareStatus;
    },

    async renuntare(id_task){
        await this.post("locatii/renuntare_task", { id_task: id_task });
        await this.refresh_info();
    },

    async reactivare(id_task){
        await this.post("locatii/reactivare_task", { id_task: id_task });
        await this.refresh_info();
    },

    async show_dialog_amanare(id_task, id_locatie){
        var response = await this.post("locatii/amanare_task", { id_task: id_task });
        await this.refresh_info();
        this.$refs['dlg-add-edit-task'].show_me("edit", id_locatie, response.Task.Id);
    },

    show_dialog_edit(id_task,id_locatie) {
        this.$refs['dlg-add-edit-task'].show_me("edit", id_locatie, id_task);
    },

    async validare( id_task ){
        var confirm =  await this.$confirm(`Sunteti sigur ?`, 'Warning');
        if( confirm )
        {
            await this.post("locatii/validare_task", { id: id_task } );
            this.refresh_info();
        }
    },

    async get_info() {
        var response            = await this.post("taskuri/get_info" );
        this.Info.Clienti       = response.clienti;
        this.Info.Utilizatori   = response.Utilizatori;
        
        var id_client_selectat       = settings.get_id_client_selectat();
        if( id_client_selectat      != null && id_client_selectat != '' ) {
            this.Filters.IdClient    = id_client_selectat;
        }
        this.Filters.IdAsignat     = response.my_id;

        this.refresh_info();
    },

    async refresh_info() {
        var response        = await this.post("taskuri/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
        this.Results        = response.Results;
        this.PaginationInfo = response.PaginationInfo;
        //
        this.select_menu_item('locatii');
    },
    
    on_sort_change({ column, prop, order }){

        this.OrderBy    = {};
        var columnName  = prop;
        var orderColumn = 'ASC';

        if( order == 'descending'   ) orderColumn = 'DESC';
        if( order == null           ) orderColumn = '';

        this.OrderBy[columnName] = orderColumn;
        this.refresh_info();
    },

    alege_client(id, nume){
        settings.set_id_client_selectat  (id);
        settings.set_nume_client_selectat(nume);

        eventBus.$emit('change-client-event');

        this.get_info();
    },

    async delete_item( item ){
        var confirm =  await this.$confirm(`Sunteti sigur ?`, 'Warning');
        if( confirm )
        {
            await this.post("taskuri/delete_item", { id: item.Id } );
            this.refresh_info();
        }
    },

    async change_client() {
        this.Filters.Stersi = "-1";
        this.refresh_info();
    },

    show_dialog () {
        this.$refs['dlg-add-edit-task'].show_me("add", -1, -1, false);
    },

    is_mobile() {
            return window.innerWidth < 1024;
        },


    async select_toti_clientii(){
        this.Filters.IdClient = "-1";
        this.refresh_info();
    },

    async select_toti_asignatii(){
        this.Filters.IdAsignat = "-1";
        this.refresh_info();
    },

    
},
destroyed(){
    eventBus.$off('clear-client-event');
    eventBus.$off('alege_client');
},
mounted(){
    this.base_url = settings.BASE_URL;

    this.get_info();

    eventBus.$on('clear-client-event', () => {
        this.get_info();
    });
}
};
</script>

<style lang="less" scoped>


.top50{
    margin-top: 20px;
}

.filtre{
    .el-input-number
    {
        width: 100% !important;
    }
}

</style>
