<template>
    <base-page>

    <el-card  class="filtre-albastre no-padding-card" >
        <el-collapse>
            <el-collapse-item name="1">
                <template slot="title">
                    <el-button style="margin-right: 15px;" icon="el-icon-plus" size="mini" type='success'  @click='show_dialog()'>Firma</el-button>
                    FILTRE
                </template>
                <div class="filtre">
                    <el-form @submit.prevent.native='refresh_info()'>
                        <el-row :gutter="20">
                            <el-col :md='3'>
                                <el-form-item label='' >
                                    <el-input v-model='Filters.Nume'  placeholder="Nume firma..." clearable/>
                                </el-form-item>
                            </el-col>
                            
                            <el-col :md='3' :xs='12' class='filter-buttons'>
                                <el-button type='primary' native-type='submit' @click='refresh_info()'> Cauta </el-button>
                            </el-col>

                            <el-col :md='4'  :xs='12' class='filter-buttons-right' >
                                <el-button type='success'  @click='show_dialog()'> Adauga firma</el-button>
                            </el-col>

                        </el-row>
                    </el-form>
                </div>
            </el-collapse-item>
        </el-collapse>
    </el-card>

    <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
    <el-table :data="Results" >
        <el-table-column sortable prop='Nume' label='Nume' min-width='150'/>
        <el-table-column fixed="right" label="Actiuni" width="100" >
            <template slot-scope="scope" >
                <el-button type="primary" size='small' icon="el-icon-edit"  circle @click="show_dialog(scope.row.Id)" />
                <el-button type="danger" v-if="scope.row.Sters=='0'" icon="el-icon-delete"  size='mini' circle @click="delete_item(scope.row)" />
            </template>
        </el-table-column>
    </el-table>
    <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
    <firme-dialog ref='dlg' @save="refresh_info()" />
</base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Firme_dialog from '@/pages/firme/Firme_dialog.vue';
import TitluPagina from '@/widgets/TitluPagina';
import eventBus from '@/backend/EventBus';

export default {
name: "firme",
extends: BasePage,
components: {
    'base-page': BasePage,
    'firme-dialog': Firme_dialog,
    'titlu-pagina': TitluPagina
},
data () {
    return {
        Results: [],
        base_url: '',
        Info: { },
        Filters: { Nume: '' },
        OrderBy: {  },
        PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
    }
},
methods: {

    async get_info() {
        var response        = await this.post("firme/get_info" );
        this.Info.clienti = response.clienti;
        this.refresh_info();
    },

    async refresh_info() {
        var response        = await this.post("firme/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
        this.Results        = response.Results;
        this.PaginationInfo = response.PaginationInfo;
        //
        this.select_menu_item('firme');
    },
    
    

    async delete_item( item ){
        var confirm =  await this.$confirm(`Sunteti sigur ?`, 'Warning');
        if( confirm )
        {
            await this.post("firme/delete_item", { id: item.Id } );
            this.refresh_info();
        }
    },

    show_dialog(id){
        this.$refs.dlg.show_me(id);
    }
},
destroyed(){
        eventBus.$off('clear-client-event');
        eventBus.$off('alege_client');
},
mounted(){
    this.base_url = settings.BASE_URL;

    if( !this.$has_right("pagina_de_firme") )  this.$router.push("/no-rights");

    this.get_info();

    eventBus.$on('clear-client-event', () => {
        this.get_info();
    });
}
};
</script>

<style lang="less" scoped>

.top50{
    margin-top: 20px;
}

.filtre{
    .el-input-number
    {
        width: 100% !important;
    }
}

</style>
