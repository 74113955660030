<template>
    <div>
      
      <el-select
        v-cancel-read-only
        id="TxtClient"
        class="full-width"
        v-model="localModelValue"
        :disabled="disabled"
        :filterable="true"
        :remote="true"
        :remote-method="fetchClienti"
        :clearable="cleareable"
        @change="onChange"
        @clear="onClear"
      >
        <el-option v-if="toti" label="Toti" value="-1" />
        <el-option
          v-for="item in clienti"
          :key="'clienti' + item.Id"
          :value="item.Id"
          :label="item.Nume"
        >
          <span style="float: left">{{ item.Nume }}</span>
          <span
            v-if="item.Sters == '1'"
            style="float: right; color: red; font-size: 13px"
          >
            <el-tag type="danger" size="mini">STERS</el-tag>
          </span>
        </el-option>
      </el-select>
    </div>
  </template>
  
  <script>
  import BasePage from "@/pages/BasePage";
  
  export default {
    name: "ClientSelect",
    extends: BasePage,
    props: {
        value: {
            type: [String],
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        cleareable: {
            type: Boolean,
            default: false,
        },
        toti:{
            type: Boolean,
            default: true,
        }
    },
    data() {
      return {
        localModelValue: this.value, // Inițializăm cu valoarea primită prin prop-ul value
        clienti: [], // Lista clienților obținută din API
      };
    },
    watch: {
      // Când valoarea primită de la părinte (value) se schimbă
      value: {
        immediate: true, // Asigură sincronizarea la inițializare
        async handler(newValue) {
            //la initializare, daca nu este -1 la value, atunci se face fetchClienti pe idul clientului
            if (newValue && newValue !== -1) {
                await this.fetchClientInitial(newValue);
            }
            if( newValue == -1 && !this.toti){
                await this.fetchFirstClientInitial();
                newValue = this.clienti[0].Id;
            }
            this.localModelValue = newValue;
        },
      },
      // Când valoarea locală (localModelValue) se schimbă
      localModelValue(newValue) {
        this.$emit("input", newValue); // Propagăm schimbarea în sus pentru v-model
      },
    },
    methods: {
      onChange(value) {
        // Emitere eveniment change după ce localModelValue a fost actualizat
        this.$nextTick(() => {
          this.$emit("change", value);
        });
      },
      onClear() {
        // Resetăm valoarea locală și emitem evenimentul clear
        this.localModelValue = null;
        this.$emit("clear");
      },
      async fetchFirstClientInitial(){
        const response = await this.post("clienti/get_remote_first_client");
        this.clienti = response.Clienti.map(client => ({
          ...client,
          Id: client.Id, // Convertim Id la tip numeric
        }));
      },
      async fetchClientInitial(id) {
        const response = await this.post("clienti/get_remote_client", { id_client: id });
        this.clienti = response.Clienti.map(client => ({
          ...client,
          Id: client.Id, // Convertim Id la tip numeric
        }));
      },

      async fetchClienti(query) {
        if (query.length < 2) {
          this.clienti = [];
          return;
        }
        const response = await this.post("clienti/get_remote_clienti", { query });
        this.clienti = response.Clienti.map(client => ({
          ...client,
          Id: client.Id, // Convertim Id la tip numeric
        }));
      },
    },
  };
  </script>
  
  <style scoped>
  .full-width {
    width: 100%;
  }
  </style>
  