<template>
        <base-page>    
       
        <el-card  class="filtre-albastre no-padding-card" >
            <el-collapse>
                <el-collapse-item title="Filtre" name="1">
                    <div class="filtre">
                        <el-form @submit.prevent.native='refresh_info()'>
                            <el-row :gutter="20">
                                
                                <el-col :md='4'>
                                    <el-form-item label='User' >
                                        <el-select v-cancel-read-only class='full-width' v-model='Filters.IdUser' filterable>
                                            <el-option label='Toti' value='-1'></el-option>
                                            <el-option v-for='item in Info.utilizatori' :key="'utilizatori' + item.Id" :label='item.Nume' :value='item.Id'></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :md='4'>
                                    <el-form-item label='Client' >
                                        <select-clienti 
                                            v-model="Filters.IdClient" 
                                            @clear="select_toti_clientii" 
                                        />
                                        <!-- <el-select v-cancel-read-only class='full-width' v-model='Filters.IdClient' filterable :clearable="true" @clear="select_toti_clientii()">
                                            <el-option label='Toti' value='-1'/>
                                            <el-option v-for='item in Info.clienti' :key="'clienti' + item.Id" :value='item.Id' :label="item.Nume" >
                                                <span style="float: left">{{ item.Nume }}</span>
                                                <span v-if="item.Sters == '1'" style="float: right; color: red; font-size: 13px"> 
                                                    <el-tag type="danger" size="mini">STERS</el-tag>
                                                </span>
                                            </el-option>

                                        </el-select> -->
                                    </el-form-item>
                                </el-col>
                                <el-col :md='3'>
                                    <el-form-item label='Data Creare Start' >
                                        <el-date-picker :picker-options="GlobalDatePickerOptions" v-model='Filters.DataCreare_Start' class=' full-width' type='date' value-format='yyyy-MM-dd' format='dd.MM.yyyy' />
                                    </el-form-item>
                                </el-col>
                                <el-col :md='3'>
                                    <el-form-item label='Data Creare Stop' >
                                        <el-date-picker :picker-options="GlobalDatePickerOptions" v-model='Filters.DataCreare_Stop' class=' full-width' type='date' value-format='yyyy-MM-dd' format='dd.MM.yyyy' />
                                    </el-form-item>
                                </el-col>
                                <el-col :md='3'>
                                    <el-form-item label='Data Expirare Start' >
                                        <el-date-picker :picker-options="GlobalDatePickerOptions" v-model='Filters.DataExpirare_Start' class=' full-width' type='date' value-format='yyyy-MM-dd' format='dd.MM.yyyy' />
                                    </el-form-item>
                                </el-col>
                                <el-col :md='3'>
                                    <el-form-item label='Data Expirare Stop' >
                                        <el-date-picker :picker-options="GlobalDatePickerOptions" v-model='Filters.DataExpirare_Stop' class=' full-width' type='date' value-format='yyyy-MM-dd' format='dd.MM.yyyy' />
                                    </el-form-item>
                                </el-col>
                                <el-col :md='4' >
                                    <el-button class='btn-aplica' type='primary' native-type='submit' @click='refresh_info()'> Aplica </el-button>
                                </el-col>
                            </el-row>
                        </el-form>
                        </div>
                </el-collapse-item>
            </el-collapse>
        </el-card>


        <el-table :data="Results" >
                <el-table-column prop='User' label='User' min-width="120"></el-table-column>
                <el-table-column prop='DataCreare' label='Data Creare' min-width="150"></el-table-column>
                <el-table-column prop='Client' label='Client' min-width="150">
                    <template slot-scope="scope">
                        <el-tooltip content="Alege client default">
                            <el-button type="warning" size='mini' icon="el-icon-right"  circle @click="alege_client(scope.row.IdClient, scope.row.Client)" />
                        </el-tooltip>
                        {{scope.row.Client}}
                    </template>
                </el-table-column>
                <el-table-column prop='Locatie' label='Locatie' min-width="150"></el-table-column>
                <el-table-column prop='Statie'  label='Statie' min-width="180">
                    <template slot-scope="scope">
                        {{scope.row.Statie}} <br/>
                        <el-tag size="mini">{{scope.row.CodStatie}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop='DataExpirarii' label='Data Expirarii' width="150">
                    <template slot-scope="scope">
                        <tag-expirare :type="scope.row.StatusExpirare" size="mini" >
                            Emisa pt {{scope.row.ZileLicenta}} zile
                        </tag-expirare>
                        <br/>
                        <tag-expirare :type="scope.row.StatusExpirare" size="mini" >
                            {{scope.row.DataExpirarii | date_nice_format}}, in {{scope.row.ZilePanaLaExpirare}} zile
                        </tag-expirare>
                        <el-tag size="small"  style="font-weight: bold !important; " :type="scope.row.NrLuniNeplatite > 3 ? 'danger' : 'success' ">
                            {{scope.row.NrLuniNeplatite}} luni neplatite
                        </el-tag>
                        
                    </template>
                </el-table-column>        

                <el-table-column prop='Cod' label='Cod' width="200" >
                    <template slot-scope="scope">
                        <input type="text" :value="scope.row.Cod" class="full-width" :id="'cod-'+scope.row.Id"/>
                    </template>
                </el-table-column>

                <el-table-column prop='Cod' label='Cod' width="100"  fixed="right">
                    <template slot-scope="scope">
                        <el-button type='success' @click="copiaza(scope.row.Id)" size="mini">Copiaza</el-button>
                    </template>
                </el-table-column>
                
        </el-table>
<el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
<Licente-dialog ref='dlg' @save="refresh_info()" />
</base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Licente_dialog from '@/pages/licente/Licente_dialog.vue';
import TitluPagina from '@/widgets/TitluPagina';
import eventBus from '@/backend/EventBus';
import TagExpirare from '@/widgets/TagExpirare';
import moment from 'moment';
import SelectClientiWidget from "@/widgets/SelectClientiWidget.vue";

export default {
    name: "licente",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'Licente-dialog': Licente_dialog,
        'titlu-pagina': TitluPagina,
        'tag-expirare': TagExpirare,
        'select-clienti': SelectClientiWidget
    },
    data () {
        return {
            Results: [],
            base_url: '',
            Info:    { utilizatori: [], clienti: [] },
            Filters: { IdUser: '-1' , IdClient: '-1' , DataCreare_Start: moment().add("-30", "days").format("YYYY-MM-DD"), DataCreare_Stop: '' , DataExpirare_Start: '', DataExpirare_Stop: '' },
            OrderBy: { },
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
            GlobalDatePickerOptions: {}
        }
    },
    methods: {

        async get_info(){
            var response        = await this.post("licente/get_info" );
            this.Info.utilizatori = response.utilizatori;
            this.Info.clienti = response.clienti;

            this.Filters.IdClient       = '-1';

            var id_client_selectat      = settings.get_id_client_selectat();
            if( id_client_selectat != null && id_client_selectat != '' ){
                this.Filters.IdClient = id_client_selectat;
            }

            this.refresh_info();
        },

        async refresh_info(){
            var response        = await this.post("licente/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Results        = response.Results;
            this.PaginationInfo = response.PaginationInfo;
            //
            this.select_menu_item('licente');
        },

        async select_toti_clientii(){
            this.Filters.IdClient = "-1";
            this.refresh_info();
        },

        async copiaza( id ) {
            
            var copyText = document.querySelector(`#cod-${id}`);
            copyText.select();
            document.execCommand("copy");
            this.$message('Am copiat ' + copyText.value);
        },

      
        
        reset() {
            this.Filters = { IdUser: '' , IdClient: '-1' , DataCreare: '' , DataExpirarii: '' };
            this.refresh_info();
        },

        async delete_item( item ){
            var confirm =  await this.$confirm(`Sunteti sigur ?`, 'Warning');
            if( confirm )
            {
                await this.post("licente/delete_item", { id: item.Id } );
                this.refresh_info();
            }
        },

        alege_client(id, nume){
            settings.set_id_client_selectat  (id);
            settings.set_nume_client_selectat(nume);

            eventBus.$emit('change-client-event');

            this.get_info();
        },

        show_dialog(id){
            this.$refs.dlg.show_me(id);
        }
    },
    destroyed(){
        eventBus.$off('clear-client-event');
        eventBus.$off('alege_client');
    },
    mounted(){
        this.base_url                   = settings.BASE_URL;
        this.GlobalDatePickerOptions    = settings.GlobalDatePickerOptions;

        if( !this.$has_right("pagina_de_istoric_licente") )  this.$router.push("/no-rights");
        
    
        eventBus.$on('clear-client-event', () => {
            this.get_info();
        });

        this.get_info();
        
    }
};
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
        label{
            width: 100% !important;
        }
        .el-form-item__label{
            width: 100% !important;
        }
    }

</style>
