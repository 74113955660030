<template>
    <base-page>
        
        <el-card  class="filtre-albastre no-padding-card" >
            <el-collapse>
                <el-collapse-item  name="1">
                    <template slot="title">
                        <el-button style="margin-right: 15px;" icon="el-icon-plus" size="mini" type='success'  @click='show_dialog()'>Statie</el-button>
                        FILTRE
                    </template>
                    <div class="filtre">
                        <el-form @submit.prevent.native='refresh_info()' >
                            <el-row :gutter="20">

                                <el-col :md='5'>
                                    <el-form-item label='Client' >
                                        <!-- <el-select filterable class='full-width' v-model='Filters.IdClient' @change='on_change_client()' :disabled='is_filtru_clienti_disabled()'>
                                            <el-option label='Toti clientii' value='-1'></el-option>
                                            <el-option v-for='item in Info.clienti' :key="'clienti' + item.Id" :label='item.Nume' :value='item.Id'></el-option>
                                        </el-select> -->
                                        <select-clienti 
                                            v-model="Filters.IdClient" 
                                            @change="on_change_client" 
                                            :disabled='is_filtru_clienti_disabled()'
                                        />
                                    </el-form-item>
                                </el-col>

                                <el-col :md='4'>
                                    <el-form-item label='Locatie' >
                                        <el-select filterable class='full-width' v-model='Filters.IdLocatie' @change='refresh_info()'>
                                            <el-option label='Toate locatiile' value='-1' />
                                            <el-option v-for='item in Info.locatii' :key="'locatie' + item.Id" :label='item.Nume' :value='item.Id' >
                                                <el-tooltip effect="dark" :content="item.Nume" placement="top-start">
                                                    <span style="float: left">{{ item.Nume }}</span>
                                                </el-tooltip>
                                                <span v-if="item.Sters == '1'" style="float: right; color: red; font-size: 13px"> 
                                                    <el-tag type="danger" size="mini">STERS</el-tag>
                                                </span>
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col :md='3'>
                                    <el-form-item label='Nume statie' >
                                        <el-input v-model='Filters.Nume' class='full-width' placeholder='Nume statie...' />
                                    </el-form-item>
                                </el-col>
                            
                                <el-col :md='3' :xs='24'>
                                    <el-form-item label='Data start' >
                                        <el-date-picker v-model='Filters.DataExpirarii_Start' :picker-options="GlobalDatePickerOptions" class=' full-width' type='date' value-format='yyyy-MM-dd' format='dd.MM.yyyy' />
                                    </el-form-item>
                                </el-col>

                                <el-col :md='3' :xs='24'>
                                    <el-form-item label='Data sfarsit' >
                                        <el-date-picker v-model='Filters.DataExpirarii_End' :picker-options="GlobalDatePickerOptions" class=' full-width' type='date' value-format='yyyy-MM-dd' format='dd.MM.yyyy' />
                                    </el-form-item>
                                </el-col>                        
                                
                                <el-col :md='3' :xs='12'>
                                    <el-form-item label='Cod' >
                                        <el-input v-model="Filters.Cod"/>
                                    </el-form-item>

                                </el-col>

                                <el-col :md='3' :xs='12' style="padding-top:48px">
                                    <el-switch 
                                        v-model="Filters.Ascunde_Expirate" 
                                        active-value="1" inactive-value="0"  active-text="Ascunde expirate"
                                        @change="refresh_info()"
                                        ></el-switch>
                                </el-col>




                            </el-row>
                            <el-row>

                                <el-col :md='2' :xs='24' class='filter-buttons' style="margin-top:15px">
                                    <el-button class="btn-aplica" type='primary' native-type='submit' @click='refresh_info()'> Cauta </el-button>
                                </el-col>
                            </el-row>
                        </el-form>
                        </div>
                </el-collapse-item>
            </el-collapse>
        </el-card>

        <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
        
        <el-table :data="Results" @sort-change='on_sort_change'>

            <el-table-column type="expand">
                <template slot-scope="props">
                    <div>
                        <el-table :data="props.row.licente" class="inner-table-licente"  :border="true">
                            <el-table-column prop="NumeUser"      label="User creare" />
                            <el-table-column prop="DataCreare"    label="Data creare" align='center'/>
                            <el-table-column prop="DataExpirarii" label="Data expirare" align='center'/>
                            <el-table-column prop="NrZile"        label="Nr Zile" align='center'>
                                <template slot-scope="scope">
                                    <el-tag style="min-width: 60px; font-weight: bold" :type="scope.row.NrZile < 8 ? 'warning' : 'default'" size="mini">
                                        {{scope.row.NrZile}}
                                    </el-tag>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </template>
            </el-table-column>
            
            
            <el-table-column prop='Id' sortable="custom" label='Id' :width="80"></el-table-column>
            <el-table-column prop='Client' label='Client' min-width="100"></el-table-column>
            <el-table-column prop='Locatie' label='Locatie'  min-width="150"></el-table-column>
            <el-table-column prop='Nume' label='Nume'  min-width="200"></el-table-column>
            <el-table-column prop='Cod' label='Cod'  min-width="180"></el-table-column>
            <el-table-column prop='DataCreare' label="Data Creare"  min-width="120"/>
            <el-table-column prop='UserCreare' label="User Creare"  min-width="100"/>
            <el-table-column prop='TipStatie' label='TipStatie'></el-table-column>
            <el-table-column prop='DataExpirarii' label='Data Expirarii' min-width="180">
                <template slot-scope="scope" >
                    <tag-expirare :type="scope.row.StatusExpirare" >
                        {{scope.row.DataExpirarii | date_nice_format}}, in {{scope.row.ZilePanaLaExpirare}} zile
                    </tag-expirare>
                </template>
            </el-table-column>

            <el-table-column fixed="right" label="Act" width="50px" v-if="is_mobile()">
                <template slot-scope="scope" >

                    <el-popover placement="bottom" trigger="click" v-if="scope.row.Status != 'amanat'">
                        <div>
                            <el-tooltip content="Alege client default">
                                <el-button type="warning" size='small' icon="el-icon-right"  circle @click="alege_client(scope.row.IdClient, scope.row.Client)" />
                            </el-tooltip>
                            <el-tooltip content="Mutare statie">
                                <el-button type="success" icon="el-icon-d-arrow-right" size='small' circle @click="show_dialog_mutare(scope.row.Id, scope.row.IdLocatie)" />
                            </el-tooltip>
                            
                            <el-tooltip content="Modificare">
                                <el-button type="primary" icon="el-icon-edit" size='small' circle @click="show_dialog(scope.row.Id)" />
                            </el-tooltip>

                            <el-tooltip content="Sterge" >
                                <el-button type="danger" icon="el-icon-delete" size='small' circle @click="delete_item(scope.row)" />
                            </el-tooltip>
                        </div>
                        <el-button slot="reference" style="float:right; padding: 10px !important; margin-left: 5px" icon="el-icon-arrow-down"  size='mini'/>
                    </el-popover>
                </template>   
            </el-table-column>

            <el-table-column fixed="right" label="Actiuni" width="180px"  v-if="!is_mobile()">
                <template slot-scope="scope" >
                    <el-tooltip content="Alege client default">
                        <el-button type="warning" size='small' icon="el-icon-right"  circle @click="alege_client(scope.row.IdClient, scope.row.Client)" />
                    </el-tooltip>
                    <el-tooltip content="Mutare statie">
                        <el-button type="success" icon="el-icon-d-arrow-right" size='small' circle @click="show_dialog_mutare(scope.row.Id, scope.row.IdLocatie)" />
                    </el-tooltip>
                    <el-tooltip content="Modificare">
                        <el-button type="primary" icon="el-icon-edit" size='small' circle @click="show_dialog(scope.row.Id)" />
                    </el-tooltip>

                    <el-tooltip content="Sterge" >
                        <el-button type="danger" icon="el-icon-delete" size='small' circle @click="delete_item(scope.row)" />
                    </el-tooltip>
                </template>   
            </el-table-column>
        </el-table>
        <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
        <Statii-dialog ref='dlg' @save="refresh_info()" />
        <mutare-statie-dialog ref="dlgMutare" @save="refresh_info()" />
    </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Statii_dialog from '@/pages/statii/Statii_dialog.vue';
import MutareStatie_dialog from '@/pages/statii/MutareStatie_dialog.vue';
import TitluPagina from '@/widgets/TitluPagina';
import TagExpirare from '@/widgets/TagExpirare';
import eventBus from '@/backend/EventBus';
import SelectClientiWidget from "@/widgets/SelectClientiWidget.vue";

export default {
    name: "statii",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'Statii-dialog': Statii_dialog,
        'mutare-statie-dialog': MutareStatie_dialog,
        'titlu-pagina': TitluPagina,
        'tag-expirare': TagExpirare,
        'select-clienti': SelectClientiWidget
    },
    data () {
        return {
            Results: [],
            base_url: '',
            Info: { clienti: [], locatii: [] },
            Filters: { Cod:"", Ascunde_Expirate: "1", IdClient: '-1', Client:'', Nume: '' , IdLocatie: '-1', DataExpirarii_Start: '', DataExpirarii_End: '' },
            OrderBy: { Id: "DESC" },
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
            GlobalDatePickerOptions: {}
        }
    },
    methods: {

        async get_info(){

            var response      = await this.post( "statii/get_info");
            this.Info.clienti = response.clienti;

            if( settings.is_client_selectat_activ() ){
                this.Filters.IdClient = settings.get_id_client_selectat();
            }

            await this.on_change_client();
            
        },

        async on_change_client(){
            
            var response      = await this.post( "statii/on_change_client", { idClient: this.Filters.IdClient } );
            this.Info.locatii = response.locatii;
            this.refresh_info();
        },

        async refresh_info(){
            var response        = await this.post("statii/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Results        = response.Results;
            this.PaginationInfo = response.PaginationInfo;
            //
            this.select_menu_item('statii');
        },

        is_filtru_clienti_disabled(){
            return settings.is_client_selectat_activ();
        },

        reset(){
            this.Filters = { IdClient: -1, Client:'', Nume: '' , Locatie: '' , DataExpirarii: ''  };
            this.refresh_info();
        },

        on_sort_change({ column, prop, order }){

            this.OrderBy    = {};
            var columnName  = prop;
            var orderColumn = 'ASC';

            if( order == 'descending'   ) orderColumn = 'DESC';
            if( order == null           ) orderColumn = '';

            this.OrderBy[columnName] = orderColumn;
            this.refresh_info();
        },


        async delete_item( item ){
            var confirm =  await this.$confirm(`Sunteti sigur ?`, 'Warning');
            if( confirm )
            {
                await this.post("statii/delete_item", { id: item.Id } );
                this.refresh_info();
            }
        },

        alege_client(id, nume){
            settings.set_id_client_selectat  (id);
            settings.set_nume_client_selectat(nume);

            eventBus.$emit('change-client-event');

            this.get_info();
        },
        
        show_dialog(id){
            this.$refs.dlg.show_me(id, this.Filters.IdClient, this.Filters.IdLocatie);
        },

        show_dialog_mutare(id, idLocatie){
            this.$refs['dlgMutare'].show_me(id, idLocatie);
        }
    },
    destroyed(){
        eventBus.$off('clear-client-event');
        eventBus.$off('alege_client');
    },
    mounted(){
        this.base_url                = settings.BASE_URL;
        this.GlobalDatePickerOptions = settings.GlobalDatePickerOptions;

        if( !this.$has_right("pagina_de_statii") )  this.$router.push("/no-rights");

        this.get_info();

        eventBus.$on('clear-client-event', () => {
            this.get_info();
        });
    }
};
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }
    .inner-table-licente{
        margin: 0 10px;
        width: 380px;
        @media(min-width: 768px){
            margin: 0 auto; 
            width: 800px
        }
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }
    .my-dt{
        
        width: 100% !important;
        
        @media(min-width:768px){ 
            width: auto !important;
        }
    }
    .expirat{
        background: black !important;
        color: white !important;
    }

    .neexpirat{
        color: black;
        background: white;
    }

    .urmeaza{
        background: yellow !important;
        color: black;
    }
</style>
