<template>
    <base-page>
    
    <el-card  class="filtre-albastre no-padding-card" >
            <el-collapse>
                <el-collapse-item title="Filtre" name="1">
                    <div class="filtre">
                        <el-form @submit.prevent.native='refresh_info()' >
                            <el-row :gutter="20">
                                
                                <el-col :md='3' :xs='24'>
                                    <el-form-item label='Firma' >
                                        <el-select v-model="Filters.IdFirma" @change='refresh_info()' class='full-width' style='margin-bottom:10px'>
                                            <el-option value="-1" label="Toate firmele"/>
                                            <el-option v-for='item in Info.firme' :key="'firma-' + item.Id" :label='item.Nume' :value='item.Id'/>
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col :md='3'>
                                    <el-form-item label='Client' >
                                        <select-clienti 
                                            v-model="Filters.IdClient" 
                                            @change="on_change_client"
                                            :disabled='is_filtru_clienti_disabled()'
                                        />
                                        <!-- <el-select filterable class='full-width' v-model='Filters.IdClient' @change='on_change_client()' :disabled='is_filtru_clienti_disabled()'>
                                            <el-option label='Toti clientii' value='-1'></el-option>
                                            <el-option v-for='item in Info.clienti' :key="'clienti' + item.Id" :label='item.Nume' :value='item.Id'></el-option>
                                        </el-select> -->
                                    </el-form-item>
                                </el-col>

                            
                                <el-col :md='3' :xs='24'>
                                    <el-form-item label='Data platii start' >
                                        <el-date-picker :picker-options="GlobalDatePickerOptions" v-model='Filters.Data_Start' class=' full-width' type='date' value-format='yyyy-MM-dd' format='dd.MM.yyyy' />
                                    </el-form-item>
                                </el-col>

                                <el-col :md='3' :xs='24'>
                                    <el-form-item label='Data platii sfarsit' >
                                        <el-date-picker :picker-options="GlobalDatePickerOptions" v-model='Filters.Data_End' class=' full-width' type='date' value-format='yyyy-MM-dd' format='dd.MM.yyyy' />
                                    </el-form-item>
                                </el-col>

                                
                                
                                
                                <el-col :md='3' :xs='24'>
                                    <el-form-item label='Status plata' >
                                        <el-select v-model="Filters.Anulate" @change='refresh_info()' class='full-width' style='margin-bottom:10px'>
                                            <el-option value="0" label="Numai activ"></el-option>
                                            <el-option value="1" label="Anulata"></el-option>
                                            <el-option value="2" label="Doar suspendate"></el-option>
                                            <el-option value="-1" label="Toate"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                
                                <el-col :md='2' :xs='12' class='filter-buttons'>
                                    <el-button class="btn-aplica" type='primary' native-type='submit' @click='refresh_info()'> Cauta </el-button>
                                </el-col>



                            
                                <el-col  :md='7' style="padding-top: 40px; text-align: right">
                                    Total incasat: 
                                    <el-tag>{{ TotalIncasat | moneyformat }} Lei</el-tag>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </el-card>
        

        <el-table :data="Results" v-loading="loadingVisible">
            <el-table-column prop='Client'      label='Client'      >
                <template slot-scope="scope">
                    {{scope.row.Client}}
                    <span v-if="scope.row.Anulat == '1'">
                        <el-tag type="danger" size="small">ANULAT</el-tag>
                    </span>
                </template>
            </el-table-column>

            <el-table-column prop='DataPlatii'  label='Data Platii' />
            <el-table-column prop='DataCreare'  label='Data Operare' >
                <template slot-scope="scope">
                    {{scope.row.DataCreare}} <br/>
                    <span style="font-size:10px">{{scope.row.Operator}}</span>
                </template>
            </el-table-column>


            <el-table-column   label='Firma facturare' >
                <template slot-scope="scope">
                    {{scope.row.Firma}}
                </template>
            </el-table-column>


            <el-table-column prop='NrDocument'  label='Document' />

            <el-table-column    label='Info'  min-width="300">
                <template slot-scope="scope" >
                    <div v-for="(locatie,idx) in scope.row.LocatiiPlatite" :key="'locatie-platita-' + idx + '-' + scope.row.Id" >
                        <strong>
                            {{locatie.Nume}}: 
                        </strong>
                        ( {{locatie.ValoareMentenantaLunara}} {{locatie.Valuta}} / Luna )
                        <br/>
                        ( {{locatie.LuniPlatite.length}} ) 
                        <el-tag
                            v-for="(l,idx2) in locatie.LuniPlatite"
                            :key="'luna-' + idx2"
                            :type=" l.Partiala == '1' ? 'warning' : 'success' "
                            style="margin-left: 2px"
                            size="small">
                            {{ l.DataPlataFormatata }}
                        </el-tag>
                    </div>
                </template>
            </el-table-column>

            <el-table-column prop='Valoare'     label='Valoare' align="right">
                <template slot-scope="scope" >
                    <div v-if="scope.row.Suspendare == '0' ">
                        <strong :style="{ 'color': scope.row.Atentionare == '1' ? '#E6A23C' : 'black' }">{{scope.row.Valoare | moneyformat}} Lei </strong>

                        <el-tooltip v-if="scope.row.Atentionare == '1'" content="Atentie! Diferenta mai mare de 50 lei la valoarea lunilor selectate fata de suma incasata"> 
                            <i class='el-icon-warning' style="color: #E6A23C"></i>
                        </el-tooltip>
                    </div>
                    <div v-else>
                        <el-tag style="background: black; color: white !important;" size="mini">
                            <i class="el-icon-lock"></i>
                            SUSPENDARE
                        </el-tag>
                    </div>
                </template>
            </el-table-column>
            
            <el-table-column fixed="right" label="Actiuni" width="100px" >
                <template slot-scope="scope" >
                    <el-tooltip content="Sterge" v-if="scope.row.Anulat == '0'">
                        <el-button type="danger" icon="el-icon-delete" size='small' circle @click="delete_item(scope.row)" />
                    </el-tooltip>
                </template>   
            </el-table-column>
        </el-table>
        <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
    </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import TitluPagina from '@/widgets/TitluPagina';
import eventBus from '@/backend/EventBus';
import moment from 'moment';
import SelectClienti from '@/widgets/SelectClientiWidget';

export default {
name: "incasari",
extends: BasePage,
components: {
    'base-page': BasePage,
    'titlu-pagina': TitluPagina,
    'select-clienti': SelectClienti
},
data () {
    return {
        Results: [],
        base_url: '',
        Info: { clienti: [], firme: [] },
        Filters: { IdFirma:'-1', IdClient: '-1', IdLocatie: '-1', Data_Start: moment().add("-30", "days").format("YYYY-MM-DD"), Data_End: '', Anulate:'0' },
        OrderBy: { },
        TotalIncasat: 0 ,
        PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
        GlobalDatePickerOptions: {}
    }
},
methods: {

    async get_info(){

        var response      = await this.post( "incasari/get_info");
        this.Info.clienti = response.clienti;
        this.Info.firme   = response.firme;
        this.Filters.IdClient = "-1";
        if( settings.is_client_selectat_activ() ){
            this.Filters.IdClient = settings.get_id_client_selectat();
        }

        await this.on_change_client();
        
    },

    async on_change_client(){
        
        var response      = await this.post( "incasari/on_change_client", { idClient: this.Filters.IdClient } );
        this.Info.locatii = response.locatii;
        await this.refresh_info();
    },

    async refresh_info(){
        var response        = await this.post("incasari/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
        this.Results        = response.Results;
        this.TotalIncasat   = response.TotalIncasat;
        this.PaginationInfo = response.PaginationInfo;
        //
        this.select_menu_item('incasari');
    },

    is_filtru_clienti_disabled(){
        return settings.is_client_selectat_activ();
    },

    reset(){
        this.Filters = { IdClient: -1, Client:'', Nume: '' , Locatie: '' , DataExpirarii: ''  };
        this.refresh_info();
    },


    async delete_item( item ){
        var confirm =  await this.$confirm(`Anulatm aceasta plata?`, 'Warning');
        if( confirm )
        {
            await this.post("incasari/delete_item", { id: item.Id } );
            this.refresh_info();
        }
    },

    show_dialog(id){
        this.$refs.dlg.show_me(id, this.Filters.IdClient, this.Filters.IdLocatie);
    }
},
destroyed(){
    eventBus.$off('clear-client-event');
    eventBus.$off('alege_client');
},
mounted(){
    this.base_url                = settings.BASE_URL;
    this.GlobalDatePickerOptions = settings.GlobalDatePickerOptions;

    if( !this.$has_right("pagina_de_incasari") )  this.$router.push("/no-rights");

    this.get_info();

    eventBus.$on('clear-client-event', () => {
        this.get_info();
    });
}
};
</script>

<style lang="less" scoped>

.top50{
    margin-top: 20px;
}

.filtre{
    .el-input-number
    {
        width: 100% !important;
    }
}
.my-dt{
    
    width: 100% !important;
    
    @media(min-width:768px){ 
        width: auto !important;
    }
}

</style>
