<template>
    <base-page>
    <el-card  class="filtre-albastre no-padding-card" >
            <el-collapse>
                <el-collapse-item name="1">
                    <template slot="title">
                        FILTRE
                    </template>

                    <div class="filtre">
                        <el-form @submit.prevent.native='refresh_info()'>
                            <el-row :gutter="20">
                                
                                <el-col :md='6' :xs='24'>
                                    <el-form-item label='Client' >
                                        <select-clienti 
                                            v-model="Filters.IdClient" 
                                            @change="change_client" 
                                            @clear="select_toti_clientii" 
                                        />
                                        <!-- <el-select 
                                            v-cancel-read-only
                                            id="TxtClient" 
                                            class='full-width' 
                                            @change="change_client()" 
                                            v-model='Filters.IdClient' 
                                            filterable 
                                            :clearable="true" 
                                            @clear="select_toti_clientii()"
                                        >
                                            <el-option label='Toti' value='-1'/>
                                            <el-option v-for='item in Info.Clienti' :key="'clienti' + item.Id" :value='item.Id' :label="item.Nume" >
                                                <span style="float: left">{{ item.Nume }}</span>
                                                <span v-if="item.Sters == '1'" style="float: right; color: red; font-size: 13px"> 
                                                    <el-tag type="danger" size="mini">STERS</el-tag>
                                                </span>
                                            </el-option>
                                        </el-select> -->
                                    </el-form-item>
                                </el-col>

                                <el-col :md='6' :xs='24'>
                                    <el-form-item label='Locatie' >
                                        <el-select 
                                            v-cancel-read-only
                                            class='full-width' 
                                            @change="refresh_info()" 
                                            v-model='Filters.IdLocatie' 
                                            filterable 
                                        >
                                            <el-option label='Toate' value='-1'/>
                                            <el-option v-for='item in Info.Locatii' :key="'locatie' + item.Id" :value='item.Id' :label="item.Nume" >
                                                <span style="float: left">{{ item.Nume }}</span>
                                                <span v-if="item.Sters == '1'" style="float: right; color: red; font-size: 13px"> 
                                                    <el-tag type="danger" size="mini">STERS</el-tag>
                                                </span>
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                               

                                <el-col :md='3' :xs='12' class='filter-buttons' >
                                    <el-form-item :label="PaginationInfo.RowCount + ' items'" >
                                        <el-button size="mini" type='primary' native-type='submit' @click='refresh_info()'> Cauta </el-button>
                                    </el-form-item>
                                </el-col>
                                


                            </el-row>
                        </el-form>
                        </div>
                </el-collapse-item>
            </el-collapse>
        </el-card>

        <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />

        <el-table :data="Results"  @sort-change='on_sort_change'  >
            <el-table-column type="expand">
                <template slot-scope="props">
                    <div>
                        <p>
                            <strong>Nr Factura:</strong> {{props.row.NrFactura}} 
                        </p>
                        <p>
                            <strong>Eroare validare:</strong> {{props.row.EroareValidare}} 
                        </p>
                        <p>
                            <strong>Raspuns:</strong> {{props.row.Response}} 
                        </p>
                    </div>
                </template>
            </el-table-column>

            <el-table-column sortable="custom" prop='Id' label='Id' min-width='100'></el-table-column>

            <el-table-column sortable prop='Client' label='Client' min-width='200'>
                <template slot-scope="scope">
                    {{scope.row.Client}} 
                    <div v-if="scope.row.ClientSters == '1'">
                        <el-tag type="danger" size="mini">STERS</el-tag>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop='Locatie'             label='Locatie'     min-width='200'>
                <template slot-scope="scope">
                    {{scope.row.Locatie}} 
                    <div v-if="scope.row.LocatieStearsa == '1'">
                        <el-tag type="danger" size="mini">LOCATIE STEARSA</el-tag>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop='Statie'              label='Statie'      min-width='200'/>
            <el-table-column prop='IdLocalFactura'      label='Id Local'    min-width='200'/>
            <el-table-column prop='DataTransmiterii'    label='Data Transmiterii' min-width='200'/>
            <el-table-column prop='Status'              label='Status'      min-width='200'/>
            
            <el-table-column fixed="right" align="center" label="Actiuni" width="200px"  >
                <template slot-scope="scope" >
                    <el-tooltip content="Download XML incarcat">
                        <el-button type="success" size='small' icon="el-icon-upload2"  circle @click="download_xml_incarcat(scope.row.Id)" />
                    </el-tooltip>
                    <el-tooltip content="Download" v-if="scope.row.Status=='validated' || scope.row.Status=='error'">
                        <el-button type="success" size='small' icon="el-icon-download"  circle @click="download(scope.row.Id)" />
                    </el-tooltip>
                    <el-tooltip content="Download ZIP" v-if="scope.row.Status=='validated'">
                        <el-button type="warning" size='small' icon="el-icon-download"  circle @click="download_zip(scope.row)" />
                    </el-tooltip>
                    <el-tooltip content="Status" >
                        <el-button type="primary" size='small' icon="el-icon-info"  circle @click="status(scope.row.Id)" />
                    </el-tooltip>
                </template>   
            </el-table-column>
            
        </el-table>
    
        <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
        

    </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import TitluPagina from '@/widgets/TitluPagina';
import eventBus from '@/backend/EventBus';


export default {
    name: "clienti",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'titlu-pagina': TitluPagina
    },
    data () {
        return {
            Results: [],
            base_url: '',
            Info: { Clienti: [], Locatii: [] },
            Filters: { IdClient: '-1', IdLocatie: '-1' },
            OrderBy: { Id: "DESC" },
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
        }
    },
   
    methods: {
        
        async get_info() {
            var response            = await this.post("facturi/get_info" );
            this.Info.Clienti       = response.Clienti;
            this.Info.Locatii       = response.Locatii;
            this.Filters.IdClient   = '-1';
            var id_client_selectat  = settings.get_id_client_selectat();

            if( id_client_selectat != null && id_client_selectat != '' ) {
                this.Filters.IdClient = id_client_selectat;
                await this.change_client();
            }else{
                this.refresh_info();
            }

            
        },

        async refresh_info() {
            var response        = await this.post("facturi/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Results        = response.Results;
            this.PaginationInfo = response.PaginationInfo;
            //
            this.select_menu_item('clienti');
        },

        async change_client() {
            this.Filters.Stersi     = "-1";
            var response            = await this.post("facturi/get_locatii_client", { id_client: this.Filters.IdClient } );
            this.Info.Locatii       = response.Locatii;
            this.Filters.IdLocatie  = "-1";
            this.refresh_info();
        },

        async select_toti_clientii(){
            this.Filters.IdClient = "-1";
            this.refresh_info();
        },

        download(id){
            window.open( this.base_url + "facturi/download/" + id, '_blank');
        },

        download_zip(f){
            window.open( this.base_url + "facturi/download_zip/" + f.IdLocalFactura, '_blank');
        },

        download_xml_incarcat(id){
            window.open( this.base_url + "facturi/download_xml_incarcat/" + id, '_blank');
        },

        status(id){
            window.open( this.base_url + "facturi/status/" + id, '_blank');
        },

        on_sort_change({ column, prop, order }){

            this.OrderBy    = {};
            var columnName  = prop;
            var orderColumn = 'ASC';

            if( order == 'descending'   ) orderColumn = 'DESC';
            if( order == null           ) orderColumn = '';

            this.OrderBy[columnName] = orderColumn;
            this.refresh_info();
        },
        
    },
    destroyed(){
        eventBus.$off('clear-client-event');
        eventBus.$off('alege_client');
    },
    mounted(){
        this.base_url       = settings.BASE_URL;
        
        if( !this.$has_right("pagina_de_facturi") )  this.$router.push("/no-rights");

        this.get_info();

        eventBus.$on('clear-client-event', () => {
            this.get_info();
        });

        eventBus.$on('alege_client', () => {
            this.get_info();
        });
    }
};
</script>

<style lang="less" scoped>
   
    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
       
    }

</style>
